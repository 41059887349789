<template>
  <div>
    <div class="air__utils__heading">
      <h5>
        <span class="mr-3">Daftar Konten Perpustakaan</span>
        <div class="pull-right">
          <router-link to="/library-content/create">
            <button class="btn btn-with-addon mr-auto btn-primary">
                  <span class="btn-addon"
                  ><i class="btn-addon-icon fe fe-plus-circle"></i
                  ></span>
              Tambah
            </button>
          </router-link>
        </div>
      </h5>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-header card-header-flex">
            <div class="d-flex flex-column justify-content-center mr-auto">
              <h5 class="mb-0">Semua Konten</h5>
            </div>
            <div class="row">
              <div class="d-flex flex-column justify-content-center mr-2">
                <a-select
                  v-model="filterOnBoarding"
                  label-in-value
                  :default-value="{ key: 'all' }"
                  style="width: 220px"
                  @change="handleChangeFilter">
                  <a-select-option value="all">
                    Semua Status On-Boarding
                  </a-select-option>
                  <a-select-option value="1">
                    On-Boarding Aktif
                  </a-select-option>
                  <a-select-option value="0">
                    On-Boarding Tidak Aktif
                  </a-select-option>
                </a-select>
              </div>
              <div class="d-flex flex-column justify-content-center mr-2">
                <a-select
                    v-model="filterCategory"
                    placeholder="Pilih kategori"
                    mode="default"
                    style="width: 180px"
                    @change="handleChange"
                >
                  <a-select-option
                      v-for="category in categories"
                      :key="category.id"
                  >{{ category.name }}
                  </a-select-option>
                </a-select>
              </div>
              <div class="d-flex flex-column justify-content-center">
                <a-input-search 
                  v-model="search"
                  placeholder="Temukan konten" 
                  enter-button 
                  @search="onSearch"
                  />
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="air__utils__scrollTable">
              <a-table
                  :columns="columns"
                  :row-key="record => record.id"
                  :data-source="data"
                  :pagination="pagination"
                  :loading="loading"
                  @change="handleTableChange"
              >
                <span slot="image" slot-scope="image, record">
                  <a :href="image.url" target="_blank" v-if="image.url || record.video.url">
                    <img v-if="record.video.url" :src="getThumbnailVideo(record.video.url)" onerror="this.src='resources/img-default.png';" class="width-64"/>
                    <img v-else-if="image.url" :src="image.url" class="width-64"/>
                  </a>
                  <img src="resources/img-default.png" class="width-64" v-else/>
                </span>
                <span slot="role" slot-scope="role">
                  <p v-for="roles in role" :key="roles.id" style="text-transform: capitalize">
                    {{ roles.name }}
                  </p>
                </span>
                <span slot="action" slot-scope="text">
                  <router-link :to="{ path: 'library-content/update/' + text }">
                    <button class="btn btn-sm btn-light mr-2">
                      <i class="fe fe-edit mr-2"/>
                      Ubah
                    </button>
                  </router-link>
                  <router-link :to="{ path: 'library-content/report/' + text }">
                    <button class="btn btn-sm btn-light mr-2">
                      <i class="fe fe-eye mr-2"/>
                      View
                    </button>
                  </router-link>
                  <button class="btn btn-sm btn-light mr-2" 
                    @click="modelPublishContent(text)" 
                    :disabled="!isDraft(text)">
                    <i class="fe fe-send"/>
                    Publikasi
                  </button>
                  <a-tooltip placement="topLeft" title="Hapus">
                    <button
                        class="btn btn-sm btn-danger"
                        @click="modalDeleteContent(text)"
                    >
                      <small>
                        <i class="fe fe-trash"/>
                      </small>
                    </button>
                  </a-tooltip>
                </span>
              </a-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import {getListCategoryLibraryContent} from "@/api/category.api";
import {deleteLibrary, getListLibraryContent, updateLibrary} from "@/api/libraryContent.api";
import LibraryContentModel from "./LibraryContentModel";
import moment from "moment";
import { mapMutations } from "vuex"

const columns = [
  {
    title: "Thumbnail",
    dataIndex: "image",
    scopedSlots: {customRender: 'image'}
  },
  {
    title: "Judul",
    dataIndex: "title",
    // scopedSlots: {customRender: 'full_name'}
  },
  {
    title: "Kategori",
    dataIndex: "category.name",
    // scopedSlots: {customRender: 'phone_number'}
  },
  {
    title: "Role",
    dataIndex: "groups",
    scopedSlots: {customRender: 'role'}
  },
  {
    title: "Status",
    dataIndex: "status_name"
  },
  {
    title: "Aksi",
    dataIndex: "id",
    scopedSlots: {customRender: "action"}
  }
];

export default {
  data() {
    return {
      data: [],
      pagination: LibraryContentModel.table.pagination,
      loading: false,
      columns,
      filterKey: '',
      categories: [],
      filterCategory: null,
      filterOnBoarding: { key: 'all' },
      search: '',
      // Route
      curRoute: ''
    };
  },
  mounted() {
    const state = this.$store.state.library.filter;
    this.filterCategory = state.filterCategory;
    this.filterOnBoarding = state.filterOnBoarding;
    this.search = state.searchText;
    this.curRoute = this.$route.fullPath;
    this.fetchData();
    this.fetchCategories()
  },
  beforeDestroy() {
    const nextRoute = this.$route.fullPath;
    if(
      !nextRoute.includes('library-content/update') && 
      !nextRoute.includes('library-content/report')) {
      this.$store.commit('CLEAR_FILTER_LIBRARY')
      this.resetPagination();
    }
  },
  methods: {
    ...mapMutations([
      'UPDATE_FILTER_SEARCH_LIBRARY',
      'UPDATE_FILTER_STATUS_LIBRARY',
      'UPDATE_FILTER_OB_LIBRARY'
    ]),
    handleTableChange(pagination) {
      this.pagination.current = pagination.current;
      this.fetchData();
    },
    isDraft(id) {
      const findData = this.data.find(it => it.id == id);
      return findData.status_id == 0;
    },
    async fetchCategories() {
      try {
        let params = {
          limit: 200,
          sort: 'desc',
        };
        let result = await getListCategoryLibraryContent(params);
        const categories = [
          { id: 0, name: 'Semua Kategori'},
          ...result.data.results
        ];
        this.categories = categories;
        const state = this.$store.state.library.filter;
        this.filterCategory = state.filterCategory;
      } catch (error) {/**/
      }
    },
    async fetchData() {
      try {
        this.loading = true;
        const params = {
          onboarding: this.filterOnBoarding.key,
          category: this.filterCategory,
          search: this.search,
          page: this.pagination.current,
          limit: this.pagination.pageSize,
        }
        let response = await getListLibraryContent(params);
        if (response.status == 200) {
          this.data = response.data.results;
          this.loading = false;
          this.pagination.total = response.data.paging.total;
        }
      } catch (error) {/**/
      }
    },
    async onSearch(value) {
      this.search = value;
      this.UPDATE_FILTER_SEARCH_LIBRARY(value);
      this.resetPagination();
      await this.fetchData()
    },
    async handleChange(value) {
      this.filterCategory = value;
      this.UPDATE_FILTER_STATUS_LIBRARY(value)
      this.resetPagination();
      this.fetchData();
    },
    async handleChangeFilter(value) {
      this.filterOnBoarding = value
      this.UPDATE_FILTER_OB_LIBRARY(value);
      this.resetPagination();
      this.fetchData();
    },
    modalDeleteContent(_id_content) {
      this.$modal.confirm({
        title: "Hapus Konten Perpustakaan",
        okText: "Yes",
        okType: "danger",
        cancelText: "No",
        content: "Apakah Anda yakin untuk menghapus perpustakaan ini?",
        onOk: () => {
          this.deleteContents(_id_content);
        },
        onCancel() {
        }
      });
    },
    modelPublishContent(_id_content) {
      this.$modal.confirm({
        title: "Publikasi Kotent",
        okText: "Yes",
        okType: "danger",
        cancelText: "No",
        content: "Apakah Anda yakin ingin publikasi konten ini?",
        onOk: () => {
          this.publishContent(_id_content)
        },
        onCancel() {
        }
      });
    },
    async deleteContents(id_content) {
      try {
        this.$nprogress.start();
        await deleteLibrary(id_content);
        this.$notification["success"]({
          message: "Sukses",
          description: "Konten Perpustakaan berhasil dihapus"
        });
        await this.fetchData();
      } catch (error) {
        // console.log(error);
      }
      this.$nprogress.done();
    },
    async publishContent(id_content) {
      try {
        const data = this.data.find(it => it.id == id_content);
        const groups = data.groups.map(it => it.name).join(',');
        const curDate = moment().format('YYYY-MM-DD HH:mm:ss');
        let formData = new FormData();
        formData.set('title', data.title);
        formData.set('category_id', data.category.id);
        formData.set('groups', groups);
        formData.set('body', data.body);
        formData.set('type', data.type);
        formData.set('published_at', curDate);
        this.$nprogress.start();
        await updateLibrary(formData, id_content);
        this.$notification["success"]({
          message: "Sukses",
          description: "Konten berhasil dipublikasi"
        });
        await this.fetchData();
      } catch (error) { /** */}
      this.$nprogress.done();
    },
    getThumbnailVideo(url) {
      const GDRIVE_INDICATOR  = 'https://drive.google.com';
      const GDRIVE_THUMBNAIL  = "https://lh3.googleusercontent.com/d/{id}";
      const YOUTUBE_INDICATOR = 'https://youtu';
      const YOUTUBE_THUMBNAIL = "https://img.youtube.com/vi/{id}/hqdefault.jpg";
      const DEFAULT_IMAGE     = "resources/img-default.png";
      let result = DEFAULT_IMAGE;
      if(typeof url == 'string' && url) {
        if(url.includes(GDRIVE_INDICATOR)) {
          let gdriveIdParams = url.split("/file/d/").pop();
          let additionalParams = gdriveIdParams.split("&").pop();
          let gdriveId = gdriveIdParams.split("/")[0];
          if(additionalParams) {
            gdriveId += "?" + additionalParams
          }
          result = GDRIVE_THUMBNAIL.replace("{id}", gdriveId)
        } else if(url.includes(YOUTUBE_INDICATOR)) {
          let lastParams = url.split("/").pop();
          let youtubeParams = lastParams.split("v=").pop();
          result = YOUTUBE_THUMBNAIL.replace("{id}", youtubeParams)
        }
      }
      return result;
    },
    resetPagination() {
      this.pagination.current = 1
    }
  }
};
</script>
<style scoped>
.width-64 {
  max-width: 85px;
  max-height: fit-content;
  width: auto;
  height: auto;
  background-repeat: no-repeat;
  background-size: contain;
}
</style>
